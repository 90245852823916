@import url('../../assets/scss/fonts.scss');

.MainContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(121, 160, 201, 0.83);
  z-index: 1002;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CardContainer {
  background: #f5f7fa;
  border-radius: 10px;
}

.HeaderContent {
  display: flex;
}

.ModalTitle {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #23364a;
  margin-left: 0.2rem;
}

.ContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-bg {
  background: none !important;
  border: none !important;
}

.HeaderContentSignUp {
  display: flex;
  justify-content: flex-end;
}

.buttonSignUp {
  margin: 0;
}

.buttonSignUp:active {
  border: none;
}
