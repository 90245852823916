.sales-image {
  height: 40px;
}
.toastError .Toastify__toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 400px;
  font-size: 0.835rem;
}
