.textColorProfileMenu {
  color: #949ca3 !important;
}

.activeProfileMenu {
  color: #23364a !important;
}

.displayProfileMenu {
  display: flex;
  justify-content: space-between;
  width: 140px;
}

.silverProfileMenu {
  font-size: 13px;
  margin: 0;
  color: #949ca3;
}
