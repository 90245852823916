@import url('../../assets/scss/fonts.scss');

.mainContainer {
  width: 80%;
  height: 70%;
  background: white;
  border-radius: 10px;

}

.mainCard {
  width: 80%;
  height: 85%;
  margin-left: 10%;
  margin-top: 5%;
  background-color: #f5f7fa !important;
}

.rowMeusDados {
  height: 2rem;
  font-size: 1.75rem;
  /* font-family: 'ProximaNova'; */
  font-weight: bold;
  color: #23364a;
}

.columnLeft {
  border-right: 2px solid rgba(112, 112, 112, 0.3);
}

.divBtn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ChangePasswordDivContainer {
  width: 50%;
}
.ChangePasswordField {
  font-family: 'ProximaNova';
  font-size: 1rem;
  font-weight: normal !important;
}

.ChangePasswordBtn {
  font-weight: bold !important;
}

.divChangeUserData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btnChangeUserData {
  max-width: 350px;
  font-weight: bold;
  margin-top: 3rem;
}

.toastError .Toastify__toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 400px;
  font-size: 0.835rem;
}
input[type='radio'] {
  accent-color: #fda704 !important;
}
input[type='radio']:checked:after {
  color: #fda704 !important;
}
