.mainCard {
  width: 350px;
  background: #f4f6f9 !important;
  padding: 40px 30px 70px 30px;
  border: 1px solid rgba($color: #79a0c9, $alpha: 0.6) !important;
  td {
    text-align: center;
    color: #23364a;
  }
}

.mainCardEmpty {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.tdSelectPlan {
  padding-left: 5px !important;
  text-align: left !important;
}

.tdBackgroundSquare {
  width: 50%;
  height: 120px !important;
}

.divSquarePlan {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  width: 65%;
  height: 70%;
  border-radius: 5px;
  margin-left: 20px;
}

.trPricePlan {
  padding-bottom: 15px !important;
  height: 50px;
  border-bottom: 1px solid rgba($color: #23364a, $alpha: 0.5);
  .tdMonthSign {
    padding-left: 5px !important;
    text-align: left !important;
  }
  .tdpricePlan {
    text-align: center;
    color: #949ca3 !important;
  }
}

.trCategories {
  padding-bottom: 15px !important;
  border-bottom: 1px solid rgba($color: #23364a, $alpha: 0.5);
  .tdTitleCategories {
    padding: 15px 0 15px 5px !important;
    text-align: left !important;
  }
  .tdCategories {
    color: #949ca3 !important;
  }
}

.trStudy {
  border-bottom: 1px solid rgba($color: #23364a, $alpha: 0.5);
  .tdStudyTrends {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
}
