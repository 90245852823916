.exportf {
    position: relative;
    width: 75px;
    margin-right: 0.5rem;
  }
  .exportf::after {
    content: "▼";
    color: white;
  font-size: 0.5rem;
  top: 10px;
  right: 10%;
  position: absolute;
  pointer-events: none;
  }