// @import url('../../assets/scss/fonts.scss');

.ContainerHelper {
  position: absolute;
  width: 1500px;
  width: 70%;
  height: 400px;
  z-index: 1042;
  display: flex;
  // align-items: center;
  justify-content: center;
}

.CardHelper {
  height: 80%;
  padding: 2rem;
  margin: 1rem;
  justify-content: center;
  margin: 0 2rem;
  padding: 0 2rem;
  box-shadow: 0 0 0.5rem rgba(112, 112, 112, 0.4);
  background-color: #ffff;
  border-radius: 0.5rem;
}

.HeaderHelper {
  display: flex;
  padding-bottom: 0;
}

.ContentHelper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}

.no-bg {
  background: none !important;
  border: none !important;
}
.containerColumnsModal {
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  color: #567faa;
  border: 2px solid #f28500;
  border-radius: 1rem;
  margin-bottom: 1rem;
  justify-content: space-around;
  width: 31.25rem;
}

.columnsModal {
  max-width: 12ch;
  word-break: break-word;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}
.LModal {
  font-size: 1.2rem;
  color: #23364a;
}
.crevronModal {
  display: flex;
  padding-top: 0.75rem;
}
.borderCardModal {
  border: 1px solid #567faa;
  margin: 1rem;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  padding: 0 2rem;
}
.categoryModal {
  font-weight: 650;
  color: #567faa;
  font-size: 0.8rem;
}
.fontCardModal {
  // display: grid;
  // grid-row: 100px 1fr;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0.5rem;
  font-size: 1rem;
  color: #23364a;
}
