.select0 {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; 
   cursor: pointer;
}
.exportf {
    position: relative;
   width: 65%;
}
.seta {
  position: relative;
 width: 65%;
}

.seta::after {
  content: "▼";
  margin-right: 0.4rem;
  color: white;
  margin-top: 0.4rem;
font-size: 0.7rem;
top: 6px;
right: 10px;
position: absolute;
pointer-events: none;
}

.exportf::after {
    content: "▼";
    margin-right: 0.4rem;
    color: white;
    margin-top: 0.4rem;
  font-size: 0.7rem;
  top: 2px !important;
  right: 1px !important;
  position: absolute;
  pointer-events: none;
}
.filters {
  position: relative;
  width: 100%;
}
.filters::after {
  content: "▼";
  color: white;
font-size: 0.5rem;
top: 11px;
right: 15%;
position: absolute;
pointer-events: none;
}
@media (max-width: 1330px) {
  .filters::after {
    content: "▼";
    color: white;
  font-size: 0.5rem;
  top: 0.6rem;
  right: 15%;
  position: absolute;
  pointer-events: none;
  }
  }

@media (max-width: 1250px) {
.filters::after {
  content: "▼";
  color: white;
font-size: 0.5rem;
top: 0.5rem;
right: 15%;
position: absolute;
pointer-events: none;
}
.exportf::after {
  content: "▼";
  margin-right: 0.4rem;
  color: white;
  margin-top: 0.3rem;
font-size: 0.7rem;
top: 2px !important;
right: 1px !important;
position: absolute;
pointer-events: none;
}
}

@media (max-width: 1080px) {
.filters::after {
  content: "▼";
  color: white;
font-size: 0.5rem;
top: 0.45rem;
right: 15%;
position: absolute;
pointer-events: none;
}
.exportf::after {
  content: "▼";
  margin-right: 0.4rem;
  color: white;
  margin-top: 0.33rem;
font-size: 0.7rem;
top: 0px !important;
right: 1px !important;
position: absolute;
pointer-events: none;
}
}
@media (max-width: 1000px) {
  .filters::after {
    content: "▼";
    color: white;
  font-size: 0.5rem;
  top: 0.35rem;
  right: 15%;
  position: absolute;
  pointer-events: none;
  }
  }
@media (min-width: 1700px) {
  .filters::after {
    content: "▼";
    color: white;
  font-size: 0.5rem;
  top: 0.7rem;
  right: 15%;
  position: absolute;
  pointer-events: none;
  }
}
@media (width: 800px) {
  .filters::after {
    content: "▼";
    color: white;
  font-size: 0.5rem;
  top: 0.35rem;
  right: 15%;
  position: absolute;
  pointer-events: none;
  }
}
@media (max-width: 1250px) {
  .select0 {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; 
   cursor: pointer;
   font-size: 60% !important;
}
}
@media (max-width: 1100px) {
  .select0 {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; 
   cursor: pointer;
   font-size: 55% !important;
}
}
@media (max-width: 1050px) {
  .select0 {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; 
   cursor: pointer;
   font-size: 50% !important;
}
}
