.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
    display: inline-block;
    background-color: #f2f2f2;
  cursor : pointer;
    padding: 5px 10px;
    font-family: sans-serif, Arial;
    font-size: 14px;
    border-radius: 4px;
}
.radio-toolbar input[type="radio"]:checked + label {
    background-color:#3e45f8;
    border : none;
    color : #ffffff;
}

#radio-title
{
  margin-bottom : 5px
}