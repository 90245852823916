.table-loading {
  overflow: hidden;
}

.pagination-row {
  padding-top: 1rem;
}

.pagination {
  justify-content: flex-end;
}
.scrollSales {
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: default #f5f7fa; 
}
.page-link {
  color: #6c757d !important;
}
.page-item.active .page-link {
  background-color: #79a0c9 !important;
  color: white !important;
  border-color: #567faa !important;
}