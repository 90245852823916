.stepper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .step:last-of-type {
    .stepDotActive {
      background-color: #F28500;
      z-index: 1000;
    }
  .stepText {
    color: #F28500;
  }  
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.6rem 0 0.8rem;
  max-width: 100px;
}

.stepDotActive {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #567FAA;
  z-index: 1000;
  margin-bottom: 0.8rem;
}

.stepDotInactive {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #00000080;
  margin-bottom: 0.8rem;
}

.stepText {
  color: #395C81;
  text-align: center;
  font-weight: bold;
}

.stepTextWhite {
  opacity: 0;
  pointer-events: none;
  text-align: center;
  font-weight: bold;
}
.cardBody {
  margin: 0 8rem !important;
}
.stepEnd {
    background-color: #F28500;

}