.mainGrid {
  display: grid;
  grid-template-rows: 150px 2fr;
  width: 70vw;
  max-width: 950px;
  background: white;
  border-radius: 10px;
  padding: 30px;
  padding-top: 0;
}

.headerMain {
  display: flex;
  align-items: end;
  justify-content: center;

  p {
    font-size: 2rem;
    font-weight: bold;
    color: #23364a;
    margin-bottom: 30px;
  }
}

.gridBody {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .planCard {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.holdCard {
  display: flex;
  align-items: center;
  justify-content: center;

  .cardCheck {
    display: grid;
    grid-template-rows: 2fr 1fr 1fr;
    height: 383px;
    width: 370px;
    background: #eeeeee;
  }
}

.check {
  grid-row: 1/2;
  display: flex;
  width: 120px;
  height: 120px;
  align-self: center;
  justify-self: center;
}

.textBackDashboard {
  grid-row: 2/3;
  display: flex;
  align-self: center;
  justify-self: center;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #23364a;
}

.btnDashboard {
  grid-row: 3/4;
  display: flex;
  align-self: flex-start;
  justify-self: center;
  height: 40px;
}
