.mainContainer {
  display: grid;
  width: 90%;
  min-height: 700px;
  background: white;
  border-radius: 10px;
}

.gridMainContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 0;
}

.leftData {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid rgba(112, 112, 112, 0.5);
  padding-top: 40px;
}

.filterCategories {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 1.25rem;
    color: #23364a;
    font-weight: bold;
  }
}

.rightData {
  padding-top: 40px;
  padding-left: 20px;
  width: 100%;
  p {
    font-size: 1.25rem;
    color: #23364a;
    font-weight: bold;
  }
}

.mainDiv {
  display: flex;
  align-items: center;
}

.orangeCircle {
  width: 90%;
  min-height: 70px;
  border-radius: 15px;
  border: 2px solid orange;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
  .orangeInside {
    flex: 1;
    color: #567faa;
    font-weight: bold;
    display: flex;
    align-items: center;
  justify-content: center;
    max-width: 100%;
    width: 100%;
    margin: 0 0.5rem;
  }
}

.selectCategories {
  min-height: 380px;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.numberOfCategories {
  p,
  span {
    font-size: 0.9rem;
  }
}

.btnContainer {
  .btnPlan {
    margin-right: 1.5rem;
    border: none;
    background-color: #b3b3a1;
    font-weight: bold;
    width: 12.5rem;
  }
  .btnConfirm {
    height: 50px;
    font-weight: bold;
    width: 12.5rem;
  }
}

.headerSelectSearch {
  display: flex;
}
