.home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4.5rem;

  flex-direction: row;
  width: 100%;
  height: calc(100vh - 70px - 80px - 96px);
  &__text-container {
    color: white;
    width: 50%;
    max-width: 537px;
    margin: 0 3rem 0 0;

    button {
      max-width: 70%;
    }
  }
  &__img-container {
    height: 500px;
    width: 500px;
  }
}
