.planbox {
  &-title {
    width: 110px;
    height: 110px;
    border-radius: 0.5rem;
    color: #ffff;
    font-weight: 700;
    margin: 22.5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-text {
    margin: 23px 0;
    margin-top: 1.3rem;
  }

  &-button {
    border: none !important;
    margin: 10px auto !important;
    font-weight: 600 !important;
    font-size: 0.6rem !important;
    width: 100%;
    height: 45px;
  }
}

.planbox-active {
  border: 1px solid #949CA3;
  border-radius: 8px;
  background-color: #fff;
  width: 170px !important;
 
}