
// Summernote

.note-editor{
  &.note-frame {
    border: 1px solid $gray-300;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: $gray-100;
      border-top: 1px solid $gray-100;
    }

    .note-editing-area{
      .note-editable, .note-codable {
        border: none;
        color: $gray-500;
        background-color: transparent;
      }
    }

  }
}

.note-btn-group{
  .note-btn{
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
  }
}

.note-status-output {
  display: none;
}

.note-editable {

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 344px;
}

.note-popover{
  border-color: $gray-300;
}

.note-popover .popover-content, 
.card-header.note-toolbar{
  background-color: $gray-100;
}

.note-toolbar {
  padding: 0 0 5px 5px !important;
}
