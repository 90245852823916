@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
    url('../fonts/Graphik-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-MediumItalic.woff2') format('woff2'),
    url('../fonts/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Light.woff2') format('woff2'),
    url('../fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-RegularItalic.woff2') format('woff2'),
    url('../fonts/Graphik-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
    url('../fonts/Graphik-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Thin.woff2') format('woff2'),
    url('../fonts/Graphik-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Bold.woff2') format('woff2'),
    url('../fonts/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('../fonts/Graphik-SuperItalic.woff2') format('woff2'),
    url('../fonts/Graphik-SuperItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-LightItalic.woff2') format('woff2'),
    url('../fonts/Graphik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('../fonts/Graphik-Super.woff2') format('woff2'),
    url('../fonts/Graphik-Super.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Semibold.woff2') format('woff2'),
    url('../fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Regular.woff2') format('woff2'),
    url('../fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-BoldItalic.woff2') format('woff2'),
    url('../fonts/Graphik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Medium.woff2') format('woff2'),
    url('../fonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Extralight.woff2') format('woff2'),
    url('../fonts/Graphik-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-Black.woff2') format('woff2'),
    url('../fonts/Graphik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-ThinItalic.woff2') format('woff2'),
    url('../fonts/Graphik-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik-BlackItalic.woff2') format('woff2'),
    url('../fonts/Graphik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Bold.otf') format('otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Italic.otf') format('otf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
