.categories-image-column {
  width: 110px;
}

.categories-image {
  height: 18.8px;
}

.divAlignCategories {
  display: flex;
  justify-content: flex-end;
}

.divMainCategories {
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.btnMoreCategories {
  height: 50px;
  width: 40%;
  margin-left: 2rem;
}

.pMoreCategories {
  font-size: 0.9rem;
  /* font-family: 'ProximaNova'; */
  display: flex;
  margin: 0 1rem 0.8rem 1.1rem;
  font-weight: 600;
  white-space: nowrap

}
.pMoreCategoriesSpan {
  font-size: 0.9rem;
  /* font-family: 'ProximaNova'; */
  display: flex;
  margin: 0 1rem 0.8rem 0.5rem;
  font-weight: 600;
  white-space: nowrap
}

.spanMoreCategories {
  color: orange;
}

.mainChangePlan {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
}

.h2ChangePlan {
  color: #f28500;
  font-weight: bold;
  font-size: 1.875rem;
  text-align: center;
}

.btnChangePlan {
  max-width: 250px;
  height: 50px;
  font-weight: bold;
  margin-top: 1.5rem;
}

.divPChangePlan {
  margin-top: 15px;
  width: 70%;
}

.pChangePlan {
  font-size: 1.5rem;
  color: #23364a;
  text-align: center;
}

.toastError .Toastify__toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 400px;
  font-size: 0.835rem;
}

@media (max-width: 1400px) {
  .mainChangePlan {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
  }
  .divPChangePlan {
    width: 100%;
    margin-top: 20px;
  }
  .pChangePlan {
    margin: 1rem 0 1rem 0;
    font-size: 1rem;
    width: 100%;
  }
  .h2ChangePlan {
    color: #f28500;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
  }
}
